.button {
  background: linear-gradient(91.2deg, #6D4AFF -12.46%, #BD00FF 99.49%);
  color: #fff;
  border-radius: 30px;
  padding: 8px 25px;
  border: 0;
  cursor: pointer;
  z-index: 1000;
  font-family: 'Plus Jakarta Sans';
  letter-spacing: 0px;
  text-align: left;

}

.button svg {
  margin-left: 10px;
}

.outlined {
  color: #6D4AFF;
  border: 1px solid #6D4AFF;
  background-color: transparent;
  padding: 8px 28px
}

.link {
  background: transparent;
  color: #6D4AFF;
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
  padding: 0;
}

.black-button {
  background: #1D1D29;
  color: #fff;
  height: 50px;
  padding: 8px 30px;
}

.white-button {
  background: #FFFFFF;
  color: #6D4AFF;
  height: 50px;
  padding: 8px 30px;
}

.button-large {
  padding: 16px 37px
}

.gradient-button {
  /* border: 1px solid #6D4AFF; */
  background: linear-gradient(91.2deg, #6D4AFF -12.46%, #BD00FF 99.49%);

  font-size: 14px;
  font-weight: 400;
  padding: 16px 30px;
  border-radius: 50px;
  transition: 1000ms;

}

.gradient-button:hover {
  background: linear-gradient(91.2deg, #6D4AFF -37.46%, #BD00FF 70.49%);
}

.gradient-button svg {
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1000ms;
}

.gradient-button:hover svg {
  transform: translateX(6px);
}