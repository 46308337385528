.blog-image-container {
  width: 100%;
  height: 540px;
  margin: auto;
  margin-bottom: 90px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.blog-image-container img {
  width: 1080px;
}

@media (max-width: 768px) {
  .blog-image-container {
    height: 240px;
    margin-bottom: 45px;
  }

  .blog-image-container img {
    width: 500px;
  }

  .blog-image-container-high {
    height: 535px;
    margin-bottom: 45px;
  }

  .blog-image-container-high img {
    width: 1080px;
    margin-left: -360px;
  }

}