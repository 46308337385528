.img-container-1 {
  margin: -50px -60px -100px 0px;
  z-index: 0;
}

.img-container-1 img {
  width: 100%;
  max-width: 545px;
}

.img-container-2 {
  margin: -8px 0px -80px -80px;
  z-index: 0;
}

.padding-114-150-110 {
  padding: 114px 110px 150px 110px;
}

@media (max-width: 768px) {
  .mob-mw-188 {
    max-width: 188px;
    padding: 35px 10px !important;
  }

  .mob-padding-60-38 {
    padding: 60px 38px;

  }

  .img-container-1 {
    margin: -10px -40px -30px -10px;
    width: unset
  }

  .img-container-1 img {
    max-width: 310px;
  }

  .img-container-2 {
    margin: -10px -60px -40px -60px;
  }

  .img-container-1 img,
  .img-container-2 img {
    width: 100%;
  }

}