.people-bcg {
  background: url('/public/images/people-bcg.jpg');
  background-size: cover;
  background-position: center;
}

.people-read {

  /* background-image: url('/public/images/people-read.jpg'); */
  background: linear-gradient(181deg, rgba(40, 40, 40, 0) 37.31%, #161618 100%), url('/public/images/people-read.jpg');
  background-size: cover;
  color: #fff;
  text-align: left;
}

.training-langs {

  background-image: url('/public/images/training-langs.jpg');
  background-size: cover;
  background-position: left center;
  /* background-position: -50px -44px; */
  width: 51%;
  text-align: left;
}


.pb20 {
  padding-bottom: 20px;
}

.img-container-5 {
  margin-bottom: -20px;
}



.mart80 {
  margin-top: 80px;
}

@media (max-width: 768px) {

  .training-langs {
    background-image: url('/public/images/training-langs.jpg');
    width: 100%;
    background-size: 950px;
    background-position: -51px bottom;
    text-align: left;
    padding: 0px 0px 300px 0px;
    background-repeat: no-repeat;
  }

  .mart80 {
    margin-top: 70px;
  }

  .mob-font-14 {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
  }

  .people-read {
    padding-top: 250px;
  }

  .people-bcg {
    margin: -110px -18px 0px -18px;
    border-radius: 0;
    padding-top: 180px;
    padding-bottom: 140px;
    background-image: url('/public/images/people-bcg-mob.jpg');
  }

  .darker {
    background: radial-gradient(647.4% 238.46% at 56.93% 8.53%, #141417 0%, rgba(20, 20, 23, 0.73) 84.79%, rgba(29, 29, 41, 0) 98.67%);
  }
}