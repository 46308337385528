.pricing-text-box {
  padding-right: 50px;
}

.pricing-pros-item {
  margin-bottom: 26px;
}

.pricing-pros-item svg {
  margin-right: 23px;
  flex-shrink: 0;
}

.pricing-pros-item p {
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: left;

}

.pricing-pros {
  margin-bottom: 30px;
}

.mw400 {
  max-width: 400px;
}

.mw450 {
  max-width: 450px;
}

.form-link {
  color: #fff;
}

.form-link:hover {
  color: #9880f7;
}

@media (max-width: 768px) {
  .pricing-pros-item p {
    font-size: 14px;
    line-height: 22px;
  }

  .heading-section {
    padding: 190px 0px 150px 0px;
  }

  .mob-mw-243 {
    max-width: 243px;
  }
}