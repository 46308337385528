.gamigication-section-bcg {
  background: #1D1D29 url('/public/images/gamigication-section-bcg.png');
  background-size: cover;
}

.img-container-3 {
  margin: -118px -151px -137px 0px;
}

.img-container-4 {
  margin: 20px -100px -100px -100px;
  flex: 1;
  background: linear-gradient(180deg, rgba(29, 29, 41, 0) 45.88%, #141417 111.69%);
}

.world-background {
  background-image: url('../../../public/images/world-map.png');
  background-size: 490px;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.world-background img {
  width: 129px;
}

.img-container-17 {
  margin: 0px -20px -71px -16px;
}

.img-container-18 {
  margin: 16px -26px -45px 0px;
}

.padding-70 {
  padding: 70px;
}

.font700 {
  font-weight: 700;
}

.mb45 {
  margin-bottom: 45px;
}

.mb70 {
  margin-bottom: 70px;
}


.padding100-110-122 {
  padding: 103px 110px 122px 110px;
}

.img-container-27 {
  margin: 65px 0px 0px -43px;
}

.img-container-28 {
  margin: -25px 0px 0px -20px;
}

@media (max-width: 768px) {

  .img-container-17 {
    margin: 0px -80px -71px -75px;
  }

  .img-container-18 {
    margin: 16px -76px -45px -60px;
  }


  .world-background img {
    width: 104px;
  }

  .img-container-28 {
    margin: 10px -24px 10px -24px;
  }

  .img-container-27 {
    margin: 0px -40px 10px -40px;
  }

  .img-container-3 {
    margin: -100px 0px -43px -43px;
  }

  .img-container-4 {
    margin: 20px -43px -48px -43px;
  }

  .gamigication-section-bcg {
    /* padding-bottom: 400px; */
    background-position-y: bottom;
    background-size: 800px;
    background-repeat: no-repeat;
    background-position-x: -400px;
    /* padding-top: 150px; */
    background-color: #1D1D29;
    margin-bottom: 20px;
    padding: 150px 40px 420px;
  }

  .mob-sato-32 {
    font-family: Satoshi;
    font-size: 32px;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: 0px;

  }
}