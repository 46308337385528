.service-elements-section {
  padding-top: 40px;
  padding-bottom: 140px;
}

.service-elements {
  padding-bottom: 0;
  overflow: initial;
}

.service-element {
  margin-bottom: -60px;
}

.service-element .font28 {
  margin: 20px 0px;
}

.mt70 {
  margin-top: 70px;
}

.mb34 {
  margin-bottom: 34px;
}

.mb24 {
  margin-bottom: 24px;
}

.container760 {
  width: 760px;
  margin: auto;
}

.padding-60-90 {
  padding: 63px 90px;
}

.fast-works-img-container {
  width: 200px;
  margin: 0px 90px 0px 0px;
}

@media (max-width: 768px) {

  .mob-padding-70-50 {
    padding: 70px 50px;
  }

  .fast-works-img-container {
    width: 165px;
    margin: 0;
  }

  .container760 {
    width: 100%;
    margin: auto;
  }


  .service-elements-section {
    padding-bottom: 73px;
  }

  .service-elements {
    padding-bottom: 0;
  }

  .service-element {
    margin-bottom: 20px;
  }

  .service-element:last-child {
    margin-bottom: -40px;
  }

}