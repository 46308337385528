.table-header {
  font-family: Plus Jakarta Sans;
  font-size: 18px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0px;

}

.table-element {
  margin: 0px 0px;
}

.table-text {
  font-size: 18px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0px;
}

@media (max-width: 768px) {
  .table-header {
    font-family: Plus Jakarta Sans;
    font-size: 12px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
  }

  .table-text {
    font-family: Plus Jakarta Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
  }
}