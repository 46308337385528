.brain-bcg {
  background: url('/public/images/brain-bcg.jpg');
  background-size: cover;
  background-position: center;
}

.img-container-33 {
  margin: 0px -50px 0px 0px;
}

.img-container-bc {
  margin-top: -20px;
}

@media (max-width: 768px) {
  .mob-mw-285 {
    max-width: 285px !important;
  }

  .mob-mart-0 {
    margin-top: 0;
  }

  .img-container-33 {
    margin: 0px -30px 0px -40px;
  }

  .container1200 {
    width: 100%;
    padding: 0px 18px;
  }

  .brain-bcg {
    background: #141417 url('/public/images/brain-bcg-mob.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    /* background-size: 540px; */
    background-position: top center;
    padding-top: 452px !important;
  }

  .mob-mb-94 {
    margin-bottom: 94px;
  }

  .mob-pt-70 {
    padding-top: 70px;
  }

  .mob-padding-46-43 {
    padding: 46px 43px;
  }
}