.sato50 {
  font-family: Satoshi;
  font-size: 50px;
  font-weight: 500;
  line-height: 60px;
  letter-spacing: 0px;

}


.img-container-13 {
  margin: -46px -67px -97px -53px;
}

.img-container-14 {
  margin: -15px -67px -67px -53px;
}

.img-container-15 {
  margin: -70px 0px -94px -74px;
}

.img-container-16 {
  margin: 0px -100px -92px -100px;
}

.img-container-22 {
  margin: 0px 0px 39px 0px;
}

.img-container-23 {
  margin: -18px 0px -20px 0px;
}

.gradient7 {
  background: linear-gradient(158.88deg, #141417 -46.2%, rgba(20, 20, 23, 0) 131.73%);
  box-shadow: 0px 8px 55px 0px #4C2DBA08;

}



@media (max-width: 768px) {
  .mob-mw-340 {
    width: 100%;
    max-width: 340px;
  }

  .img-container-23 {
    margin: 0px 0px -20px 0px;
  }

  .img-container-14 {
    margin: 0px 0px -37px 0px;
  }

  .mob-padding-46-23 {
    padding: 46px 23px;
  }

  .img-container-15 {
    margin: 20px 0px 0px 0px;
  }

  .mob-sato-26 {
    font-family: Satoshi;
    font-size: 26px;
    font-weight: 500;
    line-height: 31px;
    letter-spacing: 0px;
  }

  .mob-sato-26-700 {
    font-family: Satoshi;
    font-size: 26px;
    font-weight: 700;
    line-height: 31px;
    letter-spacing: 0px;
  }

  .mob-padding-70-48 {
    padding: 70px 48px
  }

  .img-container-16 {
    margin: 0px -156px -43px -166px;
  }

  .img-container-13 {
    margin: 10px -30px -30px -30px;

  }
}