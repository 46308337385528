.font20 {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0px;
}

.font600 {
  font-weight: 600;
}

.img-container-55 {
  margin: -10px 70px -80px 0px;
}

.img-container-29 {
  margin: -30px -20px 0px -20px;
}

.img-container-31 {
  margin: -24px -24px 0px 0px;
}

.img-container-30 {
  margin: 0px 80px 0px -50px;
}

@media (max-width: 768px) {
  .img-container-31 {
    margin: 0;
  }

  .img-container-30 {
    margin: 0px -20px 0px -20px;
  }

  .img-container-29 {
    margin: 0;
  }

  .img-container-55 {
    margin: 30px -40px 0px -30px;
  }



  .sep-mob {
    margin: -60px 0px 20px 0px;
  }

  .mob-mt-90 {
    margin-top: 90px;
  }

}