.heading-section {
  padding: 210px 0px 150px 0px;
}

.heading-section-small {
  padding: 110px 0px 150px 0px;
}

.heading-section-compact {
  padding: 130px 0px 150px 0px;
}

section.home-main-section {
  background-image: url('/public/images/main-page-img.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #070707;
}

.heading-description {
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0px;
  /* margin-top: 33px; */
  margin-bottom: 46px;
  /* max-width: 371px; */
}

.img-container-7 {
  margin: -10px 60px -60px -10px;
}

.img-container-8 {
  margin: -42px -74px -75px 30px;
}



.font13 {
  font-size: 13px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: left;
}

.risk-rating-by-dept-line {
  max-width: 398px;
}

.img-container-19 {
  margin: -10px 65px -36px -20px;
}

.mb58 {
  margin-bottom: 58px;
}

.pb50 {
  padding-bottom: 50px;
}

.margin--40 {
  margin: 0px -40px;
}

.mw900 {
  max-width: 900px;
}

.mw371 {
  max-width: 371px;
}

.mw279 {
  max-width: 279px;
}

.mw339 {
  max-width: 339px;
}

.mw890 {
  max-width: 890px;
}

.itr-bg {
  background: radial-gradient(40% 80% at 44% 20%, rgba(159, 136, 255, 0.08) 0%, rgba(238, 238, 255, 0) 100%), linear-gradient(180deg, #1D1D29 0%, #141417 139.15%);
}

.sat-card {
  width: 183px;
  height: 213px;
  border-radius: 10px;
  backdrop-filter: blur(10px);
  position: absolute;
  padding: 14px 0px 0px 16px;
}

.sat-card-pink {
  background-color: #FF67E1BF;
  top: 70px;
  left: 41px;
}

.sat-card-purple {
  background-color: #A374F6BF;
  top: 0;
  left: 130px;
}

.sat-card-blue {
  background-color: #0179FFBF;
  top: 100px;
  left: 200px;
}

@media (max-width: 768px) {

  .itr-bg {
    background: radial-gradient(50% 40% at 71% 44%, rgba(159, 136, 255, 0.08) 0%, rgba(238, 238, 255, 0) 100%), linear-gradient(180deg, #1D1D29 0%, #141417 139.15%);
  }

  .mom-mw305 {
    max-width: 305px;
  }

  .automted-card {
    background-color: rgba(29, 29, 41, 0.6);
  }

  .mob-mw304 {
    max-width: 304px;
  }

  .mob-mw299 {
    max-width: 299px;
  }

  .mob-mw263 {
    max-width: 263px;
  }

  .mob-mw245 {
    max-width: 245px;
  }

  .mob-mw341 {
    max-width: 341px;
  }

  .img-container-19 {
    margin: 50px 0px 4px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .img-container-7 {
    margin: 57px -40px -10px -25px;
  }

  .img-container-8 {
    margin: 28px -63px -38px -64px;
  }

  .img-container-19 img {
    width: 325px;
  }

  .mob-font10 {
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0px;
  }

  .mob-margin-0 {
    margin: 0;
  }

  .mob-mb-20 {
    margin-bottom: 20px;
  }

  .mob-mb-73 {
    margin-bottom: 73px;
  }

  .mob-padding-45-41 {
    padding: 45px 30px 45px 40px;
  }

  .mob-pad-0 {
    padding: 0;
  }

  .mob-font600 {
    font-weight: 600;
  }

  .heading-description {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
  }

  section.home-main-section {
    background-image: url('/public/images/main-page-img-mob.jpg');
    background-size: 600px;
    background-repeat: no-repeat;
    background-position: top center;
    background-color: #070707;
    background-position-y: 80px;
  }

  .home-main-section.heading-section {
    padding: 410px 0px 100px 0px;
  }

  .mob-padding-82-0-102 {
    padding: 82px 0px 102px;
  }



}

@keyframes slidein {
  0% {
    margin-bottom: -30px;
    opacity: 0;
  }

  29% {
    margin-bottom: -30px;
    opacity: 0;
  }

  30% {
    margin-bottom: -30px;
    opacity: .4;
  }

  100% {
    margin-bottom: 0;
    opacity: 1;
  }
}

@keyframes slidein2 {
  from {
    margin-bottom: 20px;
    opacity: .4;
  }

  to {
    margin-bottom: 0;
    opacity: 1;
  }
}

@keyframes slidein3 {
  from {
    top: 30px;
  }

  to {
    top: 0px;
  }
}

@keyframes slidein4 {
  from {
    left: 61px;
  }

  to {
    left: 41px;
  }
}

@keyframes slidein5 {
  from {
    left: 170px;
  }

  to {
    left: 200px;
  }
}

@keyframes apear {
  0% {
    opacity: 0;
  }

  29% {
    opacity: 0;
  }

  30% {
    opacity: .4;
  }

  100% {
    opacity: 1;
  }
}

.sat-animate-1 {
  animation-name: slidein3;
  animation-duration: 2s;
}

.sat-animate-2 {
  animation-name: slidein5;
  animation-duration: 2s;
}

.sat-animate-3 {
  animation-name: slidein4;
  animation-duration: 2s;
}