label {
  margin-bottom: 16px;
}

input[type="text"],
select {
  margin-bottom: 27px;

}

.column {
  display: flex;
  flex-direction: column;
}

.column-center {
  align-items: center;
}

.column-start {
  align-items: flex-start;
}

.row {
  display: flex;
  flex-direction: row;
}

.row-reverse {
  flex-direction: row-reverse;
}

.row-center {
  align-items: center;
}

.row-bottom {
  align-items: flex-end;
}

.space-between {
  justify-content: space-between;
}

.one-of-2 {
  width: calc(50% - 15px)
}

.one-of-3 {
  width: calc(33% - 15px)
}

.spacer {
  display: flex;
  flex: 1;
}

section {
  margin-bottom: -1px;
  overflow: hidden;
}

section.semi-light {
  background-color: #F9F9F9;
}

section.light,
.white-box {
  background-color: #ffffff;
  color: #1D1D29;
}

section.soft {
  background-color: #FCFBFF;
  color: #1D1D29;
}

section.soft-blue {
  background-color: #EEEEFF;
  color: #1D1D29;
}

section.light a,
section.soft a {
  color: #1D1D29;
}

section.darker {
  background: #101012;
  color: #ffffff;
}

section.dark,
.dark-box {
  background-color: #141416;
  color: #ffffff;
}

.dark-light-box {
  background-color: #1D1D29;
  color: #ffffff;
}

.dark-box-gradient {
  background: linear-gradient(180deg, #1D1D29 0%, #141417 139.15%);
  color: #ffffff;
}

.darker-box-gradient {
  background: linear-gradient(175.41deg, #19191D 0.16%, rgba(20, 20, 23, 0.31) 109.45%);
  color: #ffffff;
}

section.dark-soft {
  background-color: #1D1D29;
  color: #ffffff;
}

section.dark a,
section.dark-soft a {
  color: #ffffff;
}

.container {
  max-width: 1120px;
  margin: auto;
}

.container1300 {
  width: 1300px;
  margin: auto;
}

.container860 {
  max-width: 860px;
  margin: auto;
}

@media (max-width: 930px) {
  .container860 {
    width: 100%;
    padding: 0px 35px;
    margin: auto;
  }
}

.container1080 {
  max-width: 1080px;
  margin: auto;
}

.container1020 {
  max-width: 1020px;
  margin: auto;
}

@media (max-width: 1112px) {
  .container1080 {
    width: 100%;
    padding: 0px 18px;
    margin: auto;
  }
}

@media (max-width: 1052px) {
  .container1020 {
    width: 100%;
    padding: 0px 18px;
    margin: auto;
  }
}

h1,
h2 {
  font-family: 'Satoshi';
  font-size: 40px;
  line-height: 48px;
  font-weight: 500;
}

h3 {
  font-size: 32px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0px;
  text-align: left;

}

.font24-500 {
  font-family: Plus Jakarta Sans;
  font-size: 22px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: center;

}

.font22-500 {
  font-family: Plus Jakarta Sans;
  font-size: 22px;
  font-weight: 500;
  line-height: 31px;
  letter-spacing: 0px;
  text-align: left;


}

.sato24 {
  font-family: 'Satoshi';
  font-size: 24px;
}

.font24 {
  font-size: 24px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0px;

}

.sato26 {
  font-family: 'Satoshi';
  font-size: 26px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0px;
  text-align: left;
}

.sato32 {
  font-family: 'Satoshi';
  font-size: 32px;
  line-height: 41px;
}

.sato30 {
  font-family: 'Satoshi';
  font-size: 30px;
  font-weight: 400;
  line-height: 42px;
  letter-spacing: 0px;
  text-align: left;

}

.sato34 {
  font-family: 'Satoshi';
  font-size: 34px;
  font-weight: 400;
  line-height: 41px;
  letter-spacing: 0px;

}

.sato60 {
  font-family: Satoshi;
  font-size: 60px;
  font-weight: 700;
  line-height: 72px;
  letter-spacing: 0px;
  text-align: left;


}

.font18 {
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: left;
}

.font18-400 {
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;
  letter-spacing: 0px;
  text-align: left;
}

.font30 {
  font-size: 30px;
  line-height: 42px;
}

.font32 {
  font-size: 32px;
  font-weight: 600;
  line-height: 38px;

}

.font34 {
  font-size: 34px;
  font-weight: 500;
  line-height: 41px;
  letter-spacing: 0px;
}

.font36 {
  font-size: 36px;
  font-weight: 500;
  line-height: 43px;
}

.font28 {
  font-size: 28px;
  font-weight: 600;
  line-height: 39px;
  letter-spacing: 0px;
  text-align: left;

}

.font40 {
  font-size: 40px;
  font-weight: 600;
  line-height: 56px;
  letter-spacing: 0px;
}

.sato40 {
  font-family: 'Satoshi';
  font-size: 40px;
  font-weight: 500;
  line-height: 48px;
  letter-spacing: 0px;
  text-align: center;

}

.font42 {
  font-size: 42px;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: 0px;
}

.font64 {
  font-size: 64px;
}

.font46 {
  font-size: 46px;
  font-weight: 600;
  line-height: 55px;
  letter-spacing: 0px;
}

.pt100 {
  padding-top: 100px;
}

.pt110 {
  padding-top: 110px;
}

.pt90 {
  padding-top: 90px;
}

.pl90 {
  padding-left: 90px;
}

.pt86 {
  padding-top: 86px;
}

.padding-30-40 {
  padding: 20px 40px;
}

.padding-50-90 {
  padding: 50px 90px;
}

.padding-50-60 {
  padding: 50px 60px;
}

.padding-70-90 {
  padding: 70px 90px;
}

.padding-90-100 {
  padding: 90px 100px;
}

.padding-100-100 {
  padding: 100px 100px;
}

.padding-74-100 {
  padding: 74px 100px;
}

.padding-86-90 {
  padding: 86px 90px;
}

.padding-90-74 {
  padding: 90px 74px;
}

.padding-52-54 {
  padding: 52px 54px;
}

.padding140-150 {
  padding: 140px 0px 150px 0px;
}

.padding100-110 {
  padding: 100px 110px;
}

.pr50 {
  padding-right: 50px;
}

.pb150 {
  padding-bottom: 150px;
}





.mb10 {
  margin-bottom: 10px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb25 {
  margin-bottom: 25px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb36 {
  margin-bottom: 36px;
}

.mb40 {
  margin-bottom: 40px;
}

.mb50 {
  margin-bottom: 50px;
}

.mb70 {
  margin-bottom: 70px;
}

.mb80 {
  margin-bottom: 80px;
}

.mb87 {
  margin-bottom: 87px;
}

.mb90 {
  margin-bottom: 90px;
}

.mb100 {
  margin-bottom: 100px;
}

.mb120 {
  margin-bottom: 100px;
}

.mr10 {
  margin-right: 10px;
}

.mr28 {
  margin-right: 28px;
}

.mr60 {
  margin-right: 60px;
}

.mr67 {
  margin-right: 100px;
}

.mr79 {
  margin-right: 79px;
}

.mr90 {
  margin-right: 90px;
}



.padding80 {
  padding: 80px;
}

.text-center {
  text-align: center;
}

.gradient-text {
  background: -webkit-linear-gradient(33deg, #6D4AFF 4.11%, #D459FF 108.98%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-text-1 {

  background: -webkit-linear-gradient(341deg, #D459FF 5.05%, #6D4AFF 46.78%, #FA145F 85.36%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-text-2 {
  background: -webkit-linear-gradient(33deg, #6D4AFF -7.14%, #FA145F 14.49%, #D459FF 43.49%, #FFFFFF 63.85%, #1D1D29 103.24%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-text-3 {
  background: -webkit-linear-gradient(270deg, #FA145F 0%, rgba(250, 20, 95, 0) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-text-4 {
  background: linear-gradient(94.92deg, #6D4AFF 22.92%, #D459FF 52.47%, #FA145F 76.16%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.big-num {
  font-size: 70px;
  font-weight: 700;
  line-height: 98px;
  letter-spacing: 0px;
}

.big-num90 {
  font-size: 90px;
}

.big-num-jakarta {
  font-family: Plus Jakarta Sans;
  font-size: 80px;
  font-weight: 800;
  line-height: 96px;
  letter-spacing: 0px;
}

.content-box {
  background-color: #4C2DBA08;
  color: #ffffff;
}

.content-box-light {
  background-color: #FFFFFF;
  color: #1D1D29;
}

.br30 {
  border-radius: 30px;
  overflow: hidden;
}

.br20 {
  border-radius: 20px;
  overflow: hidden;
}

.gradient1 {
  background-color: #1d1d29ad;
  background-image: radial-gradient(30.13% 69.36% at 86.11% 35.96%, #D459FF 0%, rgba(109, 74, 255, 0.2) 49.31%, rgba(109, 74, 255, 0) 66.31%);
  box-shadow: 0px 8px 55px 0px #4C2DBA08;
}

.gradient2 {
  background-color: #1D1D29;
  background-image: radial-gradient(147.63% 178.12% at -7.87% -19.15%, #D459FF 0%, rgba(109, 74, 255, 0.2) 51.07%, rgba(109, 74, 255, 0) 71.07%);
}

.gradient3 {
  background-color: #181821;
  background-image: radial-gradient(55.42% 117.45% at 21.16% -37.55%, #D459FF 12.3%, rgba(109, 74, 255, 0.2) 86%, rgba(109, 74, 255, 0) 107%), radial-gradient(36.44% 93.71% at 96.04% -7.12%, #6D4AFF 0%, rgba(109, 74, 255, 0.2) 86.28%, rgba(109, 74, 255, 0) 114.28%);
}

.gradient4 {
  background: linear-gradient(180.53deg, #1D1D29 -39.35%, rgba(29, 29, 41, 0) 199.75%);
  color: #fff;
}

.gradient5 {
  background: radial-gradient(67.95% 160.46% at 102.5% -46.77%, rgba(212, 89, 255, 0.76) 0%, rgba(109, 74, 255, 0.2) 36.34%, rgba(109, 74, 255, 0) 45.34%), radial-gradient(34.72% 59.69% at 46.31% 124%, #D459FF80 0%, rgba(109, 74, 255, 0.2) 67.81%, rgba(109, 74, 255, 0) 85.81%);
}

.gradient6 {
  background: radial-gradient(214.18% 214.18% at 35.92% -41.18%, #D459FF 0%, #834DFF 40.36%, #542ABD 63.6%, #1D1D29 90.14%);
}


.box-shadow {
  box-shadow: 0px 30px 55px 0px #4C2DBA0A;
  position: relative;
}

input[type="text"],
select {
  border: 1px solid #CECEFF;
  height: 50px;
  border-radius: 6px;
}


.darker input[type="text"],
.darker select,
.dark input[type="text"],
.dark select {
  border: 1px solid #2C2C36;
  background: #141417;
  color: #fff;
  height: 50px;
  border-radius: 6px;
}

.risk-ranging-line {
  height: 1px;
  background: linear-gradient(90deg, #6D4AFF 0%, rgba(109, 74, 255, 0) 100%);
}

.risk-ranging-line-darker {
  height: 1px;
  background: linear-gradient(90deg, #6D4AFF4D 0%, rgba(109, 74, 255, 0) 100%);
}

.width520 {
  width: 520px;
}

.width740 {
  width: 740px;
}

.hide {
  display: none;
}

.promo-block {
  padding: 71px 80px 63px 110px;
  width: calc(100% - 560px);
}

.secondary-heading {
  font-family: Plus Jakarta Sans;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #B6B6FF;
  margin-bottom: 30px;
  word-spacing: 8px;
}

.word-space-0 {
  word-spacing: unset;

}

.promo-heading {
  color: #9C84FF;
  font-family: Satoshi;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;

  text-transform: uppercase;
}

.img460 {
  width: 460px;
}

.font25-500 {
  font-family: Plus Jakarta Sans;
  font-size: 25px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0px;
  /* text-align: left; */
}

.font35 {
  font-family: Plus Jakarta Sans;
  font-size: 35px;
  font-weight: 500;
  line-height: 42px;
  letter-spacing: 0px;
  text-align: left;

}


.font16 {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0px;
}

.font16-400 {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
}

.font16-400-center {
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
}

.font42-600 {
  font-family: Plus Jakarta Sans;
  font-size: 42px;
  font-weight: 600;
  line-height: 59px;
  letter-spacing: 0px;
  /* text-align: left; */

}

.ml20 {
  margin-left: 20px;
}

.font20-500 {
  font-family: Plus Jakarta Sans;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0px;
  /* text-align: left; */

}


.font22 {
  font-family: Plus Jakarta Sans;
  font-size: 22px;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0px;
  /* text-align: center; */

}

.font50 {
  font-family: Plus Jakarta Sans;
  font-size: 50px;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: 0px;
  text-align: center;

}

.img-container-56 {
  margin: -30px 0px -80px 0px;
}

.font70 {
  font-size: 70px;
  font-weight: 600;
  line-height: 84px;
  letter-spacing: 0px;

}

.font66 {
  font-size: 66px;
  font-weight: 600;
  line-height: 84px;
  letter-spacing: 0px;

}

.padding-80-110 {
  padding: 80px 110px;
}


.padding-74-80 {
  padding: 74px 80px;
}

.padding-112-110 {
  padding: 112px 110px;
}

.padding-160-90 {
  padding: 189px 90px;
}

.padding-60-110 {
  padding: 60px 110px;
}

.padding-20-110 {
  padding: 20px 110px;
}

.padding-85-100-90 {
  padding: 85px 100px 90px 100px;
}

.padding-110 {
  padding: 110px;
}

.padding-88-110 {
  padding: 88px 110px;
}

.padding-71-36 {
  padding: 71px 36px;
}


.font500 {
  font-weight: 500;
}

.padding-67-90 {
  padding: 67px 90px;
}

.mb60 {
  margin-bottom: 60px;
}

.pt180 {
  padding-top: 160px;
}


.pt143 {
  padding-top: 143px;
}

.pb143 {
  padding-bottom: 143px;
}

.mw-506 {
  max-width: 506px;
}

.h420 {
  height: 420px;
}


.pt140 {
  padding-top: 140px;
}

.pt150 {
  padding-top: 150px;

}


.mb140 {
  margin-bottom: 140px;
}

.pt54 {
  padding-top: 54px;
}

.mb150 {
  margin-bottom: 150px;
}

.mb-150 {
  margin-bottom: 150px;

}

.pb40 {
  padding-bottom: 40px;
}

.pb100 {
  padding-bottom: 100px;
}

.padding-40-60 {
  padding: 40px 60px;
}

.padding-136-120 {
  padding: 136px 120px;
}

.padding-136-120-166 {
  padding: 136px 120px 166px 120px;
}

.container1200 {
  max-width: 1200px;
  margin: auto;
}

.pb180 {
  padding-bottom: 180px;
}

.mart146 {
  margin-top: 146px;
}

.pb170 {
  padding-bottom: 170px;
}

.pt120 {
  padding-top: 120px;
}

.padding-20-0 {
  padding: 20px 0px;
}


.pt130 {
  padding-top: 130px;
}

.pb30 {
  padding-bottom: 30px;
}

.pb0 {
  padding-bottom: 0 !important;
}

.pr-0 {
  padding-right: 0px;
}

.get-started-home-orig {
  background-image: url('/public/images/get-started-home-orig.jpg');
  width: 560px;
  background-size: cover;
  background-position: center;
}


.sato36 {
  font-family: Satoshi;
  font-size: 34px;
  font-weight: 500;
  line-height: 41px;
  letter-spacing: 0px;

}

.pt200 {
  padding-top: 200px;
}

.mt140 {
  margin-top: 140px;
}

.mt220 {
  margin-top: 220px;
}

.padding-80-0 {
  padding: 80px 0px;
}

.mt30 {
  margin-top: 30px;
}

.pb80 {
  padding-bottom: 80px;
}

.pt160 {
  padding-top: 160px;
}

.img-container-32 {
  margin: 30px 30px 30px 0px;
}

.mart70 {
  margin-top: 70px;
}

@media (max-width: 1300px) {
  .container1300 {
    width: 100%;
    padding: 0px 18px;
  }
}

@media (max-width: 1220px) {
  .container {
    padding: 0px 28px;
  }
}

@media (max-width: 768px) {
  .mob-font-26 {
    font-size: 26px;
    font-weight: 600;
    line-height: 31px;
    letter-spacing: 0px;
  }

  .mob-font-14 {
    font-family: Plus Jakarta Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    /* text-align: center; */

  }

  .img-container-56 {
    margin: -30px 0px 0px 0px;
  }

  .mob-h340 {
    height: auto;
  }

  .mob-pb-20 {
    padding-bottom: 20px;
  }

  .mob-pb-60 {
    padding-bottom: 60px;
  }

  .mob-pb-94 {
    padding-bottom: 94px;
  }

  .get-started-home-orig {
    width: 100%;
    height: 340px;
    background-size: cover;
    background-position: center;
  }

  .mob-mw-265 {
    max-width: 265px !important;
  }

  .mob-mw-285 {
    max-width: 285px !important;
  }

  .mob-mw-243 {
    max-width: 243px !important;
  }

  .mob-padding-15-0-43 {
    padding: 15px 0px 43px;
  }

  .mob-padding-93-20-0 {
    padding: 93px 20px 0px;
  }

  .mob-pb-100 {
    padding-bottom: 100px !important;
  }

  .mob-mb-90 {
    margin-bottom: 90px;
  }

  .mob-mb-0 {
    margin-bottom: 0px;
  }

  .mob-mb-10 {
    margin-bottom: 10px;
  }

  .mob-pt-0 {
    padding-top: 0px;
  }

  .mob-pt-20 {
    padding-top: 20px;
  }

  .mob-pt-40 {
    padding-top: 40px;
  }

  .mob-pt-50 {
    padding-top: 50px;
  }

  .mob-pt-60 {
    padding-top: 60px;
  }

  .mob-mt-0 {
    margin-top: 0px;
  }

  .mob-mt-40 {
    margin-top: 40px;
  }


  .gradient3 {
    background-color: #181821;
    background-image: radial-gradient(61.42% 77.45% at 7.16% -12.55%, #D459FF90 12.3%, rgba(109, 74, 255, 0.2) 86%, rgba(109, 74, 255, 0) 107%), radial-gradient(57.44% 64.71% at 96.04% -7.12%, #6D4AFF 0%, rgba(109, 74, 255, 0.2) 86.28%, rgba(109, 74, 255, 0) 114.28%);
  }

  .gradient2 {
    background-color: #1D1D29;
    background-image: radial-gradient(308% 133% at 29% -48%, #D459FF 0%, rgba(109, 74, 255, 0.2) 50%, rgba(109, 74, 255, 0) 69%);
    background-repeat: no-repeat;
  }

  .mob-font-16 {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  }

  .mob-word-space-0 {
    word-spacing: unset;

  }

  .mob-sato20 {
    font-family: Satoshi;
    font-size: 20px;
  }

  .mob-sato45 {
    font-family: Satoshi;
    font-size: 45px;
    font-weight: 700;
    line-height: 54px;
    letter-spacing: 0px;
    text-align: center;

  }

  .mob-img,
  .img460 {
    width: 100%;
    margin: 0;
  }

  .mob-gradient1 {
    background: #4C2DBA08 radial-gradient(300px 300px at 87.11% 78.96%, #D459FF 0%, rgba(109, 74, 255, 0.2) 49.31%, rgba(109, 74, 255, 0) 66.31%);
  }

  .mob-padding-30-20 {
    padding: 30px 20px;
  }

  .mob-padding-46-43 {
    padding: 46px 43px;
  }

  .mob-padding-46-0 {
    padding: 46px 0px;
  }

  .mob-padding-46-20 {
    padding: 46px 20px;
  }

  .mob-mw-282 {
    max-width: 282px;
  }

  .mob-padding-80-32 {
    padding: 80px 32px;
  }

  .mob-font14 {
    font-family: Plus Jakarta Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;

  }

  .mob-font14-500 {
    font-family: Plus Jakarta Sans;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
  }

  .promo-block {
    padding: 45px 40px;
    width: 100%;
  }

  h1,
  h2 {
    font-size: 26px;
    line-height: 31px;
    letter-spacing: 0px;
  }

  .container {
    padding: 0px 18px;
  }

  .big-num {
    font-size: 60px;
    line-height: 84px;
  }

  .big-num90 {
    font-size: 80px;
    line-height: 84px;
  }

  .mob-column {
    flex-direction: column;
  }

  .mob-column-center {
    align-items: center;
  }

  .mob-column-reverse {
    flex-direction: column-reverse;
  }

  .mob-row {
    flex-direction: row;
  }

  .mob-br20 {
    border-radius: 20px;
  }

  .mob-mb-20 {
    margin-bottom: 20px;
  }

  .mob-mb-30 {
    margin-bottom: 30px;
  }

  .mob-mb-40 {
    margin-bottom: 40px;
  }

  .mob-mb-50 {
    margin-bottom: 50px;
  }

  .mob-mb-60 {
    margin-bottom: 60px;
  }

  .mob-mb70 {
    margin-bottom: 70px;
  }

  .mob-mb-16 {
    margin-bottom: 16px;
  }

  .mob-fullwidth {
    width: 100%;
  }

  .mob-text-center {
    text-align: center;
  }

  .mob-hide {
    display: none;
  }

  .mob-show {
    display: block;
  }

  .mob-flex {
    display: flex;
  }

  .mob-fullsize {
    margin: -110px -18px 0px -18px;
    border-radius: 0;
    padding-top: 60px;
  }

  .mob-font18 {
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0px;
    text-align: left;
  }

  .mob-font22 {
    font-size: 22px;
    font-weight: 400;
    line-height: 31px;
  }

  .mob-font26 {
    font-size: 26px;
    font-weight: 500;
    line-height: 31px;
    letter-spacing: 0px;
  }

  .mob-font32 {
    font-size: 32px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0px;
  }

  .mob-font45 {
    font-size: 45px;
    font-weight: 500;
    line-height: 54px;
    letter-spacing: 0px;
  }

  .mob-font42 {
    font-size: 42px;
    font-weight: 600;
    line-height: 50px;
    letter-spacing: 0px;

  }

  .mob-font-600 {
    font-weight: 600;
  }


  .mob-font500 {
    font-weight: 500;
  }

  .mob-font700 {
    font-weight: 700;
  }

  .heading-section.gradient5 {
    padding: 190px 0px 85px 0px;
  }

  .gradient5 {
    background: radial-gradient(278.95% 180.46% at 102.5% -46.77%, rgba(212, 89, 255, 0.76) 0%, rgba(109, 74, 255, 0.2) 36.34%, rgba(109, 74, 255, 0) 45.34%), radial-gradient(92.72% 59.69% at 46.31% 124%, #D459FF80 0%, rgba(109, 74, 255, 0.2) 67.81%, rgba(109, 74, 255, 0) 85.81%);

  }

  .mob-pt-90 {
    padding-top: 90px;
  }
}