/* .heading-description {
  font-family: Plus Jakarta Sans;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0px;

} */

.img-container-9 {
  margin: 0px 0px 30px -30px;
}

.img-container-10 {
  margin: -108px -89px -128px 57px;
}

.img-container-11 {
  margin: -34px -29px -48px 110px;
}

.img-container-12 {
  margin: -10px 0px -50px 80px;
}


.img-container-20 {
  margin: 70px 0px 30px 0px;
}

.img-container-21 {
  margin: 85px 0px 0px -40px;
}



@media (max-width: 768px) {
  .mob-padding-0 {
    padding: 0;
  }

  /* .mob-font14 {
    font-family: Plus Jakarta Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
  } */

  .mob-padding-46-43 {
    padding: 46px 43px;
  }

  .mob-padding-46-26 {
    padding: 46px 26px;
  }

  .img-container-12 {
    margin: 0px -30px 0px -30px;
  }

  .img-container-21 {
    margin: 20px -80px 30px -63px;
  }

  .mob-font-24 {
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0px;

  }

  .img-container-11 {
    margin: 50px -20px 10px 0px;
  }

  .img-container-10 {
    margin: 30px -60px -107px -70px;

  }

  .ml20 {
    margin-left: 0px;
  }

  .img-container-9 {
    margin: 0px -30px 30px -30px;
  }

  .mob-font-28 {
    font-size: 28px;
    font-weight: 600;
    line-height: 39px;
    letter-spacing: 0px;
  }

  .mob-font12 {
    font-size: 12px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0px;
  }

  .mob-pr-0 {
    padding-right: 0;
  }
}