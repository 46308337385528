.footer {
  padding: 94px 0px 56px 0px;
  /* border-top: 1px solid #4F4F4F; */
}

/* .footer.light { */
/* border-top: 1px solid #fff; */
/* } */


.copyright-text {
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: left;
  max-width: 660px;

}

.footer-links-group-header {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  margin-bottom: 40px;
  margin-top: 60px;
}

.footer-links-group-item {
  margin-bottom: 17px;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  line-height: 17px;
  transition: 200ms;
}

.footer-link-item {
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  margin-right: 30px;
  transition: 200ms;
}

.footer-link-item:hover,
.footer-links-group-item:hover {
  color: #6D4AFF;
}

.dark .footer-link-item:hover,
.dark .footer-links-group-item:hover {
  color: #9880f7;
}

.mr92 {
  margin-right: 80px;
}

.iso-27001-logo {
  width: 90px;
  margin-bottom: 20px;
}


@media (max-width: 768px) {
  .mob-mr-0 {
    margin-right: 0px;
  }

  .mob-mr-39 {
    margin-right: 39px;
  }

  .footer-link-item {
    line-height: 17px;
    letter-spacing: 0px;
    text-align: left;

    margin-top: 35px;

  }


  .footer {
    padding: 50px 0px 56px 0px;
    /* border-top: 1px solid #4F4F4F; */
  }

  .copyright-text {
    font-size: 9px;
    font-weight: 500;
    line-height: 13px;
    text-align: left;
  }

  .footer-links-group-item {
    font-size: 12px;
  }

}