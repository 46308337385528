.nav {
  height: 70px;
  margin-bottom: -70px;
  backdrop-filter: blur(10px);
  border-bottom: 1px solid #2F2F2F;
  position: fixed;
  width: 100%;
  z-index: 10000;
}

.nav.light {
  border-bottom: 1px solid #E5E5E6;
}

.nav-item {
  color: #fff;
  text-decoration: none;
  margin: 0px 10px;
  padding: 9px 13px;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  position: relative;
}

.light .nav-item {
  color: #1D1D29;
}

.nav-item:hover {
  transition: 200ms;
  background-color: #6D4AFF1F;
}

.light .nav-item:hover {
  background-color: #6D4AFF1F;
}

.select-lang-btn {
  position: relative;
  margin-left: 10px;
  cursor: pointer;
  padding: 9px 13px;
  border-radius: 8px;
}

.select-lang-btn:hover {
  transition: 200ms;
  background-color: #6D4AFF1F;
}

.select-lang-menu-item {
  cursor: pointer;
  padding: 9px 13px;
  border-radius: 8px;
}

.select-lang-menu-item:hover {
  transition: 200ms;
  background-color: #6D4AFF1F;
}

.pop-menu {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  transition: 400ms;
}

.nav-item:hover .pop-menu {
  display: block;
}

.burger-menu-button {
  display: none;
  cursor: pointer;
  padding: 20px 0px;
  background: transparent;

}

.burger-menu-button:hover {
  background: radial-gradient(circle, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 50%);

}

.mobile-menu {
  position: fixed;
  background-color: #101012;
  color: #fff;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  padding: 28px;
}

.mobile-menu-item {
  padding: 20px 0px;
}

.lock-scroll {
  overflow: hidden;
}

.mobile-menu-item p,
.mobile-menu-item a {
  font-family: Plus Jakarta Sans;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  cursor: pointer;
  text-decoration: none;
  color: #ffffff;

}

.pointer {
  cursor: pointer;

}

.pop-menu-item {
  color: #fff;
  margin-left: -16px;
  padding: 16px 21px 16px 19px;
  margin-right: 16px;
  cursor: pointer;
  max-width: 390px;
  /* height: 70px; */
  border-radius: 12px;
}

.pop-menu-item:hover {
  background: #141417;
}

.mt40 {
  margin-top: 40px;
}

.pop-menu-item-arrow {
  transition: 300ms;
  opacity: 0;
  margin-left: -15px;
}

.pop-menu-item:hover .pop-menu-item-arrow {
  opacity: 1;
  transform: translateX(5px);
}


@media (max-width: 768px) {
  .nav {
    height: 90px;
    border: 0;
  }


  .burger-menu-button {
    display: block;
  }

}