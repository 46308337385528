.on-demand-learning {
  background-image: url('/public/images/on-dem-learn.jpg');
  background-size: cover;
  color: #fff;
  text-align: center;
}

.ondemand-gradient {
  background: linear-gradient(180deg, rgba(40, 40, 40, 0) 37.31%, #000000 100%);

}

.img-container-24 {
  margin: -50px 0px -130px -10px;
}

.pt230 {
  padding-top: 230px;
}

.img-container-25 {
  margin: -45px -70px -65px 0px;
}

.img-container-26 {
  margin: -80px -70px 15px 0px;
}





@media (max-width: 768px) {
  .img-container-26 {
    margin: -30px -20px -40px -20px;
  }

  .mob-padding-50-20 {
    padding: 50px 20px;
  }

  .img-container-25 {
    margin: -25px -50px -25px -50px;
  }

  .mob-mb100 {
    margin-bottom: 100px;
  }

  .mob-pt-30 {
    padding-top: 30px;
  }

  .img-container-24 {
    margin: -30px 0px 30px 0px;
  }

  .on-demand-learning {
    padding-top: 210px;
  }
}