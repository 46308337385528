.plasma-bcg {
  background: url('/public/images/plasma-bcg.jpg');
  background-size: cover;
  background-position: center;
}

.realtime-compliance {
  margin: 0px 0px 0px 30px;
  background-image: url('/public/images/realtime-compliance.jpg');
  background-size: cover;
  background-position: right center;
  text-align: left;
}

.img-container-6 {
  margin: -30px 0px;
}

.pb160 {
  padding-bottom: 160px;
}


@media (max-width: 768px) {
  .mob-mw-265 {
    max-width: 265px;
  }

  .plasma-bcg {
    background-image: url('/public/images/plasma-bcg-mob.jpg');
    background-color: #020103 !important;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center top;
    padding-top: 400px;
    padding-bottom: 70px;
  }

  .img-container-6 {
    margin: -30px -64px;
  }

  .realtime-compliance {
    padding-bottom: 360px;
    background-size: 961px;
    background-repeat: no-repeat;
    background-position: right bottom;
    margin: 0;
  }
}