.eye-bcg {
  background: url('/public/images/eye-bcg.png');
  background-size: cover;
  background-position: center;
}


@media (max-width: 768px) {
  .mart70 {
    margin-top: 0px;
  }

  .img-container-32 {
    margin: 0;
  }

  .eye-bcg {
    background: url('/public/images/eye-bcg-mob.jpg');
    background-size: cover;
    background-position: bottom center;
    background-repeat: no-repeat;
    padding: 80px 70px 430px 70px;
    margin: -20px -40px 0px -60px;
  }

  .mob-mw-203 {
    max-width: 203px;
  }
}